<template>
  <v-autocomplete
    v-model="selected"
    :items="items"
    :search-input.sync="search"
    hide-selected
    :item-text="getText"
    item-value="id"
    :multiple="multiple"
    :label="label"
    small-chips
    deletable-chips
    return-object
    no-filter
    v-bind="$attrs"
    v-on="$listeners"
    @change="$emit('update:chargingStation', selected)"
  ></v-autocomplete>
</template>

<script>
import { ref } from '@vue/composition-api'
import gql from 'graphql-tag'

export default {
  name: 'SelectChargingStation',
  props: {
    chargingStation: {
      required: false,
      type: Object,
      default: null,
    },
    device_id: {
      required: false,
      type: String,
      default: null,
    },
    integration: {
      required: false,
      type: String,
      default: null,
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false,
    },
    label: {
      required: false,
      type: String,
      default: 'Charging Point',
    },
  },
  apollo: {
    charging_stations: {
      query: gql`
        query ($search: String, $integration: String, $device_id: ID) {
          charging_stations(search: $search, integration: $integration, device_id: $device_id) {
            data {
              id
              name
              charging_point_id
              distance,
              gps
            }
          }
        }
      `,
      skip() {
        return !this.integration
      },
      variables() {
        return {
          integration: this.integration,
          device_id: this.device_id,
          search: this.search,
        }
      },
      debounce: 350,
      result(result) {
        this.items = JSON.parse(JSON.stringify(result.data.charging_stations.data))
        this.chargingStationFound()
      },
      errorPolicy: 'all',
      error(e) {
        if (e.networkError) this.$toastr.e('Network Error')
        else this.$toastr.e('Server Error')
      },
    },
  },
  setup() {
    const items = ref([])
    const selected = ref(null)
    const search = ref(null)

    return {
      items,
      selected,
      search,
    }
  },

  watch: {
    selected(newVal, oldVal) {
      if (newVal === null && oldVal !== null && this.search !== null) this.search = null
    },
    /* eslint-disable */

    chargingStation: {
      handler() {
        if (this.chargingStation) {
          this.selected = this.chargingStation
        }
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.$apollo.queries.charging_stations.refetch()
  },

  methods: {
    getText(item) {
      return `${item.charging_point_id} - ${item.name}`
    },
    chargingStationFound() {
      if (this.chargingStation !== null) {
        const isFound = this.items.some(element => {
          return element.id === this.chargingStation.id ? true : false
        })

        if (!isFound) {
          this.items.push(this.chargingStation)
        }
      }
    },
  },
}
</script>
