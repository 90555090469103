<template>
  <div>
    <loading v-if="$apollo.loading" />
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-uppercase"
              @click="changeOrder('NAME')"
            >
              Name
              <sorting-arrows :direction="column === 'NAME' ? order : null" />
            </th>
            <th
              class="text-center text-uppercase"
              @click="changeOrder('STATUS')"
            >
              Status
              <sorting-arrows :direction="column === 'STATUS' ? order : null" />
            </th>
            <th
              class="text-center text-uppercase"
              @click="changeOrder('ID_IMEI')"
            >
              IMEI
              <sorting-arrows :direction="column === 'ID_IMEI' ? order : null" />
            </th>
            <th
              class="text-center text-uppercase"
              @click="changeOrder('DESCRIPTIVE_LOCATION')"
            >
              Location
              <sorting-arrows :direction="column === 'DESCRIPTIVE_LOCATION' ? order : null" />
            </th>
            <th
              class="text-center text-uppercase"
              @click="changeOrder('UPDATED_AT')"
            >
              Last Updated
              <sorting-arrows :direction="column === 'UPDATED_AT' ? order : null" />
            </th>
            <th
              class="text-center text-uppercase"
            >
              Availability
            </th>
            <th class="text-center text-uppercase">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="allDevices && allDevices.length == 0">
            <div class="text-center my-5">
              <h3>
                No items
              </h3>
            </div>
          </tr>
          <tr
            v-for="item in allDevices"
            :key="item.id"
          >
            <td>
              <div v-if="item.name">
                {{ item.name }} (<span v-if="item.organizations.length > 0">{{ getOrganizations(item.organizations) }}</span>)
              </div>
              <div v-else>
                /
              </div>
            </td>
            <td class="text-center">
              <v-chip
                :color="getStatusColor(item)"
                small
                class="font-weight-semibold text-capitalize"
              >
                {{ item.status }} {{ checkStatus(item) }}
              </v-chip>
            </td>
            <td class="text-center">
              {{ item.id_imei }}
            </td>
            <td class="text-center">
              <div v-if="item.charging_station && item.charging_station.gps">
                <v-btn
                  plain
                  @click="openGoogleMap(item.charging_station.gps)"
                >
                  <div class="d-flex justify-center align-center">
                    <v-icon size="20">
                      {{ icons.mdiMap }}
                    </v-icon>
                  </div>
                </v-btn>
                <p v-if="item.charging_station.name">
                  {{ item.charging_station.name }}
                </p>
              </div>
              <div v-else>
                /
              </div>
            </td>
            <td class="text-center">
              {{ item.updated_at }}
            </td>
            <td class="text-center">
              <div class="d-flex justify-center align-center">
                <div class="d-flex justify-center align-center">
                  <div class="mr-2">
                    <v-icon size="20">
                      {{ icons.mdiEvPlugCcs2 }}
                    </v-icon>
                  </div>
                  <span>{{ item.charging_station ? item.charging_station.ccs : '' }}</span>
                </div>
                <div class="d-flex justify-center align-center">
                  <div class="mr-2 ml-2">
                    <v-icon size="20">
                      {{ icons.mdiEvPlugChademo }}
                    </v-icon>
                  </div>
                  <span>{{ item.charging_station ? item.charging_station.chademo : '' }}</span>
                </div>
                <div class="d-flex justify-center align-center">
                  <div class="mr-2 ml-2">
                    <v-icon size="20">
                      {{ icons.mdiEvPlugType2 }}
                    </v-icon>
                  </div>
                  <span>{{ item.charging_station ? item.charging_station.type_two : '' }}</span>
                </div>
              </div>
            </td>
            <td class="text-center">
              <v-icon
                v-if="item.isAlert && item.status === 'online'"
                size="18"
                color="warning"
              >
                {{ icons.mdiAlert }}
              </v-icon>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <router-link
                    :disabled="item.status == 'unclaimed' && role == 'admin'"
                    :to="'devices/' + item.id"
                  >
                    <v-btn
                      icon
                      small
                      :to="'devices/' + item.id"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="22">
                        {{ icons.mdiViewListOutline }}
                      </v-icon>
                    </v-btn>
                  </router-link>
                </template>
                <span>Sensors</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    @click="() => {
                      selectedDevice = item
                      showModal = true
                    }"
                    v-on="on"
                  >
                    <v-icon size="20">
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <!-- <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              v-if="role == 'superadmin'"
                              icon
                              small
                              @click="() => {
                              selectedDevice = item
                              showDeleteModal = true
                              }"
                              v-bind="attrs"
                              v-on="on"
                          >
                              <v-icon size="20">
                              {{ icons.mdiTrashCanOutline  }}
                              </v-icon>
                          </v-btn>
                      </template>
                      <span>Delete</span>
                  </v-tooltip> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <pagination
      v-if="devices"
      :count="countPages"
      :page.sync="currentPage"
    />
    <AvailabilityDialogForm
      v-if="showModal"
      :show.sync="showModal"
      :device.sync="selectedDevice"
      :role="role"
      @success="getDevices(); showModal = false"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
// eslint-disable-next-line object-curly-newline
import {
  mdiPencilOutline,
  mdiTrashCanOutline,
  mdiViewListOutline,
  mdiArrowUpDownBold,
  mdiAlert,
  mdiMap,
  mdiEvPlugCcs2,
  mdiEvPlugChademo,
  mdiEvPlugType2,
} from '@mdi/js'
// import axios from '@axios'
import Pagination from '@/views/components/pagination/Pagination.vue'
import gql from 'graphql-tag'
import Loading from '../Loading.vue'
import AvailabilityDialogForm from './AvailabilityDialogForm.vue'
import SortingArrows from '../SortingArrows.vue'

export default {
  name: 'DevicesTable',
  components: {
    Pagination,
    Loading,
    AvailabilityDialogForm,
    SortingArrows,
  },
  props: {
    search: {
      required: false,
      default: null,
      type: String,
    },
  },
  apollo: {
    devices: {
      query: gql`
          query ($page: Int!, $search: String, $deviceType: [DeviceType], $orderBy: [QueryDevicesOrderByOrderByClause!]) {
            devices(page: $page, search: $search, device_type: $deviceType, orderBy: $orderBy) {
              data {
                id
                name
                id_imei
                status
                location
                descriptive_location
                battery
                maintenance
                integration
                updated_at
                device_type

                charging_station {
                  id
                  name
                  distance
                  charging_point_id
                  type_two
                  ccs
                  chademo
                  gps
                }

                sensors {
                  id
                  name
                  last_value
                  alert_sent
                }

                organizations {
                  id
                  name
                }
              }
              paginatorInfo {
                currentPage
                lastPage
              }
            }
          }
        `,
      debounce: 350,
      variables() {
        return {
          search: this.search ?? undefined,
          orderBy: [
            {
              column: this.column ?? 'ID_IMEI',
              order: this.order ?? 'ASC',
            },
          ],
          page: this.currentPage,
          deviceType: ['LEGACY'],
        }
      },
      result(result) {
        /* eslint-disable */
            console.log(result)
          this.allDevices = result.data.devices.data
          this.allDevices = this.allDevices.map(device => {
            let isTilted = false
            let isMovement = false
            let isAlert = false

            device.sensors.forEach(sensor => {
              if (sensor.name === 'Tilt' && sensor.alert_sent) {
                isAlert = true
                isTilted = true
              }
              if (sensor.name === 'GPS' && sensor.alert_sent) {
                isAlert = true
                isMovement = true
              }
            })

            return {
              ...device,
              isTilted: isTilted,
              isAlert: isAlert,
              isMovement: isMovement,
            }
          })

          this.countPages = result.data.devices.paginatorInfo.lastPage
          this.currentPage = result.data.devices.paginatorInfo.currentPage
        },
        errorPolicy: 'all',
        error(e) {
          if (e.networkError) this.$toastr.e('Network Error')
          else this.$toastr.e('Server Error')
        },
      },
    },
    setup(props) {
      const role = localStorage.getItem('userAbility')
      const currentPage = ref(1)
      const countPages = ref(1)
      const searchProp = ref(props.search)
      const showModal = ref(false)
      const column = ref('NAME')
      const order = ref('ASC')
      const allDevices = ref([])
      const selectedDevice = ref(null)

      const getBatteryColor = number => {
        if (number >= 30) return 'success'
        if (number < 30 && number >= 10) return 'warning'

        return 'error'
      }

      return {
        icons: {
          mdiPencilOutline,
          mdiTrashCanOutline,
          mdiViewListOutline,
          mdiArrowUpDownBold,
          mdiAlert,
          mdiMap,
          mdiEvPlugCcs2,
          mdiEvPlugChademo,
          mdiEvPlugType2,
        },
        role,
        allDevices,
        currentPage,
        countPages,
        searchProp,
        showModal,
        column,
        order,
        getBatteryColor,
        selectedDevice,
      }
    },

    methods: {
      changeOrder(column) {
        this.column = column
        this.order = this.order === 'ASC' ? 'DESC' : 'ASC'
      },
      getOrganizations(organizations) {
          return organizations.map(org => org.name).toString()
    },
      openGoogleMap(location) {
        const gps = JSON.parse(location)
        const urlSuffix = [gps['lat'], gps['long']]

        // eslint-disable-next-line
        window.open('https://www.google.com/maps/search/?api=1&query=' + urlSuffix, '_blank')
      },
      getOrganizations(organizations) {
        return organizations.map(org => org.name).toString()
      },
      getDevices() {
        this.$apollo.queries.devices.refetch()
      },
      getStatusColor(device) {
        if (device.status === 'online' || (device.status === 'online' && device.maintenance)) return 'success'
        if (device.status === 'offline' && device.maintenance) return 'secondary'
        if (device.status === 'offline') return 'error'
        if (device.status === 'ready for setup') return 'warning'

        return 'purple'
      },
      checkStatus(device) {
        let status = ''

        if (device.status == 'online' || device.status == 'offline') {
          if (device.isMovement) status = ' + Movement'
          if (device.isTilted) status = status + ' + Tilted'
        }

        if (device.maintenance) status = status + ' + Maintenance'

        return status
      },
    },
  }
  </script>
